import { IThemeConfig } from "../helpers";

const darkTheme: IThemeConfig = {
  name: "dark",
  colors: {
    background: "rgb(39, 49, 56)",
    main: "rgb(199, 199, 199)",
    secondary: "rgb(136, 136, 136)",
    border: "rgba(195, 195, 195, 0.14)",
    hover: "rgb(16, 26, 32)"
  }
};

export default darkTheme;
